@import "custom-variables";
//Required
@import "node_modules/bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";
//Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
//FontAwesome
$fa-font-path: '/static/fonts';
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

html {
  --brand: #3F58AA;
  scrollbar-color: #3F58AA Canvas !important;
}

:root { accent-color: var(--brand); }
:focus-visible { outline-color: var(--brand); }
::selection { background-color: var(--brand); }
::marker { color: var(--brand); }

:is(
  ::-webkit-calendar-picker-indicator,
  ::-webkit-clear-button,
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button
) {
  color: var(--brand);
}

@media screen and
  (prefers-reduced-motion: reduce),
  (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

.fc-event-main {
    color: black !important;
}

.fc-daygrid-dot-event {
    color: black !important;
}

.fc-timeGridDay-view {
    .fc-event-main {
        font-size: large !important;
    }
}

.fc-timeGridWeek-view {
    .fc-event-main {
        font-size: 0.9rem;
    }
}

#content {
  padding: 40px 15px;
}

#userdropdown > li {
  padding: 0 0.3em;
}

#userdropdown > li, #activity {
  .media-object {
    max-width: 3em;
  }
}

.table tbody > tr > td.vert-align {
  vertical-align: middle;
}

.align-right {
  text-align: right;
}

textarea {
  width: 100%;
  resize: vertical;
}

.custom-combobox {
  display: block;
}

.event-mic-photo {
  max-width: 2em;
  border-radius: 0.25em;
}

.item-description {
  margin-left: 1em;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

del {
  background-color: #f2dede;
  border-radius: 3px;
  padding: 0.1em;
}

ins {
  background-color: #dff0d8;
  border-radius: 3px;
  padding: 0.1em;
}

.nav-link {
  color: $gray-400 !important;
}

.btn-light {
    background-color: $gray-200 !important;
}

.skip-link {
  background: $dark;
  outline: unset;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-link:focus {
  transform: translateY(45px); /* TODO Remove absolute positioning */
}

.errorlist > li {
    list-style-type: none;
}

pre {
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

svg {
    display: inline;
    white-space: no-wrap;
}

span.fas {
    padding-left: 0.1em !important;
    padding-right: 0.3em !important;
}

html.embedded {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  justify-content: center;

  body{
    padding:0;
    width:100%;
    background:none;
    overflow: auto;
  }

  .embed_container{
    border:5px solid #e9e9e9;
    padding:12px 0px;
    min-height:100%;
    width:100%;
  }

  .source{
    background: url('/static/imgs/pyrigs-avatar.png') no-repeat;
    background-size: 16px 16px;
    padding-left: 20px;
    color: #000;
  }

  h3{
    margin-top:10px;
    margin-bottom:5px;
  }

  p{
    margin-bottom:2px;
    font-size: 11px;
  }

  .event-mic-photo{
    max-width: 3em;
  }
}

.markdown {
  h1 {
    font-size: $h1-font-size * 0.75;
  }
  h2 {
    font-size: $h2-font-size * 0.8;
  }
  h3 {
    font-size: $h3-font-size * 0.85;
  }
  h4 {
    font-size: $h4-font-size * 0.9;
  }
  h5 {
    font-size: $h5-font-size * 0.95;
  }

  img {
    max-width: 100%;
  }
}

#rigboard {
  .markdown {
    img {
      max-width: 30rem;
    }
  }
}

.card, .card-header, .btn, input, select, .CodeMirror, .editor-toolbar, .card-img-top {
    border-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.bootstrap-select, button.btn.dropdown-toggle.bs-placeholder.btn-light {
    padding-right: 1rem !important;
}
// New implementation of class dropped in Bootstrap 3
.dl-horizontal {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.7rem 0;
}
.dl-horizontal > dd, .dl-horizontal .markdown > p {
    margin-bottom: 0 !important;
}
